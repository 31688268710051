<template>
  <div class="container">
    <!-- 顶部navbar -->
    <div class="navbar" v-if="!isLogin">
      <div class="content">
        <img
          src="../assets/img/logo.png"
          alt=""
          @click="$router.replace('/home')"
          style="cursor: pointer"
        />
        <div class="navbar-right">
          <div class="navbar-item">
            <a href="javascript:;">下载APP</a>
            <img
              src="../assets/img/xiala.png"
              alt=""
              @mouseenter="isShow = true"
              @mouseleave="isShow = false"
            />
            <img
              v-show="isShow"
              class="download-qr"
              :src="net + about_service.app_download"
              alt=""
              style="background: #fff; padding: 2px"
            />
          </div>
          <div class="navbar-item">
            <router-link to="/aboutUs">关于我们</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/login">登录</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/register">注册</router-link>
          </div>
        </div>
      </div>
    </div>
    <navbar v-else></navbar>
    <!-- 轮播图 -->
    <div class="banner">
      <div class="content">
        <el-carousel trigger="click" height="800px">
          <el-carousel-item v-for="(item, index) in banner_imgs" :key="index">
            <a :href="item.link" target="_blank">
              <img v-lazy="net + item.pic" alt="" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="about-section">
      <div class="content">
        <div class="about-title">
          <h4>关于我们</h4>
          <div class="line"></div>
        </div>
        <div class="about-text">
          <p>
            {{ about_service.content }}
          </p>
          <video
            :src="net + about_service.video"
            controls="controls"
            poster="../assets/img/poster.png"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getHome } from "@network/home";
import navbar from "../components/Navbar.vue";
export default {
  name: "AboutUs",
  data() {
    return {
      net: BASE_URL,
      about_service: {}, //关于和服务
      banner_imgs: [],
      isShow: false,
    };
  },
  created() {
    this.getHome();
  },

  components: {
    navbar,
  },

  methods: {
    bannerTo(type) {
      if (type == 0) {
        return false;
      } else if (type == 1) {
        this.$router.push("/joinRaise");
      } else if (type == 2) {
        this.$router.push("/customized");
      }
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });
          return false;
        } else {
          this.about_service = res.data.homePage;
          this.banner_imgs = res.data.carousel;
        }
      });
    },
  },

  computed: {
    isLogin() {
      let token = this.$storage.get("token");
      return token;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../assets/css/AboutUs.less";
</style>